import { getVerandaValues } from './getVerandaValues';
import { getTerraceValues } from './getTerraceValues';
import { getPorchValues } from './getPorchValues';
import getData from "./getData";


export default function ({ houseId, region, area, blocks }) {
  const data = getData();
  let areaPriceValues = [];

  Object.keys(area).forEach((key) => {
    const block = data.blocks.find((block) => block.machine_name === key);
    const blockOptions = block.values.options;
    let calculatedPrice;
    switch (key) {
      case 'koshaus_main_veranda':
      case 'cubehaus_main_veranda':
      case 'dublhaus_main_veranda':
      case 'additional_veranda':
      case 'dublhaus_additional_veranda':
        const activeIndex = area[key];
        const { foundationPrice } = getVerandaValues(blockOptions[activeIndex], region, houseId);
        if (foundationPrice !== undefined) {
          calculatedPrice = foundationPrice;
        } else {
          calculatedPrice = 0;
        }
        break;

      case 'terrace':
        const terraceValue = area[key];
        const { cost_per_m2 } = getTerraceValues(blockOptions[0].value, region, houseId);
        calculatedPrice = terraceValue * cost_per_m2;
        break;

      case 'porch':
        const porchIndexArr = area[key];
        calculatedPrice = porchIndexArr.map((index) => {
          const { foundation_price } = getPorchValues(blockOptions[index].value, region, houseId);
          const value = blocks[key][index].value;

          return foundation_price !== undefined ? (foundation_price * value) : 0;
        });
        break;
    }

    areaPriceValues = areaPriceValues.concat(calculatedPrice);
  });

  return areaPriceValues.reduce((sum, price) => (
    sum += parseFloat(price)
  ), 0);
}
