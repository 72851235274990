export const roundToPrecision = (value, precision = 1) => {

  // If value contain a letter return value
  if (isNaN(Number(value))) {
    return value;
  }

  if (Number(value) < precision) {
    return value;
  }

  return Math.round(Math.round(value) / precision) * precision;
};