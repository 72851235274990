import data from "../data/data";
import dataOverride from "../data/data.override";
import optionValueChanger from "./optionValueChanger";
import addPercentageMarkup from "./addPercentageMarkup";
import getCalculatedBlock from "./getCalculatedBlock";
import getInitializedBlockObj from "./getInitializedBlockObj";
import {roundToPrecision} from "./roundToPrecision";
import mergeDataFiles from "./mergeDataFiles";
import currencyExchange from "./currencyExchange";

export default function () {
  const resultData = mergeDataFiles(data, dataOverride);

  //  The first step is to add a markup for all houses and marked blocks

  //  Get markup percentage
  const markupPercentage = resultData.configuration['markup_percentage'];

  //  Continue if the value is correct
  if (markupPercentage > 0) {

    //  Add markup to each house
    const houseModels = Object.keys(resultData['houses']);
    houseModels.forEach(model => {

      //  Get house
      const house = resultData['houses'][model];
      if (!house["markup"]) return;

      //  Add a markup to the price value
      house.price = optionValueChanger(
        house.price,
        (value)=>addPercentageMarkup(value, markupPercentage)
      );

      // Round to tenths
      house.price = optionValueChanger(
        house.price,
        (value)=>roundToPrecision(value, 10)
      );
    });

    //  Add markup to blocks

    //  Get block
    resultData.blocks = resultData.blocks.map(block => {
      if (block['markup']) {

        //  Get each option
        block.values.options = block.values.options.map(option => {

          //  Add a markup to the values
          option.value = optionValueChanger(
            option.value,
            (value)=>addPercentageMarkup(value, markupPercentage),
            ['cost_per_m2', 'foundation_price']
          );

          // Round to tenths
          option.value = optionValueChanger(
            option.value,
            (value)=>roundToPrecision(value, 10),
            ['cost_per_m2', 'foundation_price']
          );

          // TODO Fix for Architectural Project
          if (option.hasOwnProperty('sub_options')){
            option['sub_options'] = option['sub_options'].map(subOption => {
              subOption.value = optionValueChanger(
                subOption.value,
                (value)=>addPercentageMarkup(value, markupPercentage)
              );

              // Round to tenths
              subOption.value = optionValueChanger(
                subOption.value,
                (value)=>roundToPrecision(value, 10)
              );

              return subOption;
            });
          }

          return option
        })
      }

      return block;
    })

  }


  let { countries } = resultData.configuration;
  countries = Object.keys(countries);

  // For Russian calculator
  if (countries.includes('spb')) {

    // Currency exchange
    const { exchange_rates } = resultData.configuration;
    const roundPrecision = 1000;
    if (typeof exchange_rates === "undefined") {
      throw new Error(`No exchange rate`);
    }

    //  Exchange price to each house
    const houses = Object.keys(resultData['houses']);
    houses.forEach(model => {

      //  Get house
      const house = resultData['houses'][model];

      //  Exchange price value
      house.price = optionValueChanger(
        house.price,
        (value)=>currencyExchange(value, exchange_rates)
      );

      // Round
      house.price = optionValueChanger(
        house.price,
        (value)=>roundToPrecision(value, roundPrecision)
      );
    });

    //  Exchange blocks prices
    //  Get block
    resultData.blocks = resultData.blocks.map(block => {

      // TODO Fix for Foundation
      if (block['machine_name'] === 'foundation') {
        const options = block['values']['options'];
        options.forEach(option => {
          const id = option['machine_name'];

          switch (id) {
            case '0': {
              option['selections'].forEach( selection => {
                selection.value = optionValueChanger(
                  selection.value,
                  (value)=>currencyExchange(value, exchange_rates)
                );

                // Round
                selection.value = optionValueChanger(
                  selection.value,
                  (value)=>roundToPrecision(value, roundPrecision)
                );
              });

              break;
            }

            case '1': break;

            default: {
              //  Exchange the values
              option.value = optionValueChanger(
                option.value,
                (value)=>currencyExchange(value, exchange_rates)
              );

              // Round
              option.value = optionValueChanger(
                option.value,
                (value)=>roundToPrecision(value, roundPrecision)
              );
            }
          }
        });
        return block;
      }

      //  Get each option
      block.values.options = block.values.options.map(option => {

        //  Exchange the values
        option.value = optionValueChanger(
          option.value,
          (value)=>currencyExchange(value, exchange_rates)
        );

        // Round
        option.value = optionValueChanger(
          option.value,
          (value)=>roundToPrecision(value, roundPrecision)
        );

        // TODO Fix for Architectural Project
        if (option.hasOwnProperty('sub_options')){
          option['sub_options'] = option['sub_options'].map(subOption => {
            subOption.value = optionValueChanger(
              subOption.value,
              (value)=>currencyExchange(value, exchange_rates)
            );

            // Round
            subOption.value = optionValueChanger(
              subOption.value,
              (value)=>roundToPrecision(value, roundPrecision)
            );

            return subOption;
          });
        }

        return option
      });

      return block;
    });
  }

  // For BY calculator
  if (countries.includes('by')) {
    const roundPrecision = 100;

    //  Round price for each house
    const houses = Object.keys(resultData['houses']);
    houses.forEach(model => {

      //  Get house
      const house = resultData['houses'][model];

      // Round
      house.price = optionValueChanger(
        house.price,
        (value)=>roundToPrecision(value, roundPrecision)
      );
    });

    //  Round blocks prices
    //  Get block
    resultData.blocks = resultData.blocks.map(block => {

      // TODO Fix for Foundation
      if (block['machine_name'] === 'foundation') {
        const options = block['values']['options'];
        options.forEach(option => {
          const id = option['machine_name'];

          switch (id) {
            case '0': {
              option['selections'].forEach( selection => {

                // Round
                selection.value = optionValueChanger(
                  selection.value,
                  (value)=>roundToPrecision(value, roundPrecision)
                );
              });

              break;
            }

            case '1': break;

            default: {

              // Round
              option.value = optionValueChanger(
                option.value,
                (value)=>roundToPrecision(value, roundPrecision)
              );
            }
          }
        });
        return block;
      }

      //  Get each option
      block.values.options = block.values.options.map(option => {

        // Round
        option.value = optionValueChanger(
          option.value,
          (value)=>roundToPrecision(value, roundPrecision)
        );

        // TODO Fix for Architectural Project
        if (option.hasOwnProperty('sub_options')){
          option['sub_options'] = option['sub_options'].map(subOption => {

            // Round
            subOption.value = optionValueChanger(
              subOption.value,
              (value)=>roundToPrecision(value, roundPrecision)
            );

            return subOption;
          });
        }

        return option
      });

      return block;
    });
  }

  //  The next step is to include marked blocks and points in the price of the house
  //  We have an array of blocks and options that are marked as "included_in_price"
  for (let i = resultData['blocks'].length - 1; i >= 0; i--) {
    const  block = resultData['blocks'][i];

    //  Get each option
    const blockOptions = block.values.options;

    // If at least one option is marked "included_in_price"
    if (blockOptions.some( option => (option["included_in_price"]) )) {

      //  Initial block
      const initialBlock = getInitializedBlockObj(block);

      //  Calculate block

      //  We have a list of houses
      const housesKeys = Object.keys(resultData['houses']);

      //  For each house, we calculate the values of the block
      housesKeys.forEach(houseId => {
        const house = resultData['houses'][houseId];
        if (house['fix_price']) return;
        const housePrice = house.price;

        const countriesList = Object.keys(resultData.configuration.countries);

        //  Initial house price for each country
        if (typeof housePrice !== "object"){
          house.price = {};
          countriesList.forEach(country => {
            house.price[country] = housePrice;
          });
        }

        //  Calculate block for each country
        countriesList.forEach(country => {

          //  Get to know the region
          const { regions } = resultData.configuration;
          const region = Object.keys(regions).find(region => (
            regions[region].includes(country)
          ));

          if (typeof region == "undefined") {
            throw new Error(`The country "${country}" does not have a region`);
          }

          //  Get to know the DDP_Distance
          const DDP_distance = resultData.configuration.countries[country].distance;

          //  Calculate block
          const calculatedBlock = getCalculatedBlock(block, initialBlock, {
            region,
            houseId,
            DDP_distance
          });

          //  We save each marked option in the cost of the house
          blockOptions.forEach(option => {
            if (option['included_in_price']) {
              if (!option.hasOwnProperty('machine_name')) {
                throw new Error(`The option does not have a machine name field`);
              }
              const id = option['machine_name'];
              const optionPrice = Number(calculatedBlock[id].price);

              // Price must be a number
              if (!isNaN(optionPrice)) {
                const housePriceValue = Number(house.price[country]);
                house.price[country] = housePriceValue + optionPrice;
              }
            }
          });
        });

      });

      //  After entering the price in the price of the house, delete the marked options
      for (let index = blockOptions.length - 1; index >= 0; index--){
        const option = blockOptions[index];

        if (option['included_in_price']) {
          blockOptions.splice(index, 1);
        }
      }
    }

    // Remove options marked as display: false
    for (let index = blockOptions.length - 1; index >= 0; index--){
      const option = blockOptions[index];

      if (option['display'] === false) {
        blockOptions.splice(index, 1);
      }
    }

    // Delete a block
    const blockId = block['machine_name'];
    const blockIndex = resultData['blocks'].findIndex(block => (block['machine_name']) === blockId);

    // If it has no options
    if (blockOptions.length === 0) {
      resultData.blocks.splice(blockIndex, 1);
    } else {

      // If the display is false
      if (block.display === false) {
        resultData.blocks.splice(blockIndex, 1);
      }
    }
  }

  return resultData;
}